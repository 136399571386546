import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2B3C89', // var(--colorOne)
        },
        secondary: {
            main: '#161E45', // var(--colorTwo)
        },
        text: {
            primary: '#2B2C31', // var(--colorLabel)
        },
    },
    typography: {
        fontFamily: 'Open Sans, sans-serif',
    },
});

export default theme;
