// src/components/Header/Header.js

import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Button,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const navItems = [
    // { id: 'home', title: 'Home', href: '/' },
    // {
    //     id: 'services',
    //     title: 'Services',
    //     subMenu: [
    //         { title: 'Consulting', href: '/services/consulting' },
    //         { title: 'Business Applications', href: '/services/business-applications' },
    //         // Add other submenu items here
    //     ],
    // },
    // Add other nav items here
];

const Header = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuId, setOpenMenuId] = useState(null);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuOpen = (event, menuId) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuId(menuId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenMenuId(null);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            {/* Logo in Drawer */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <a href="/" aria-label="Migration Register Home">
                    <img
                        src="/assets/logo.png"
                        alt="Migration Register Logo"
                        style={{ width: '150px', height: 'auto' }}
                    />
                </a>
            </Box>
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.id} disablePadding>
                        <ListItemText>
                            <a
                                href={item.href}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                onClick={handleDrawerToggle} // Closes drawer on link click
                            >
                                {item.title}
                            </a>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            {/* Updated AppBar with White Background */}
            <AppBar position="static" sx={{ backgroundColor: 'white', color: 'primary.main' }}>
                <Toolbar>
                    {/* Mobile Menu Icon */}
                    <IconButton
                        color="primary" // Changed from 'inherit' to 'primary' for visibility on white background
                        aria-label="Open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* Logo */}
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <a href="/" aria-label="Migration Register Home">
                            <img
                                src="/assets/logo.png"
                                alt="Migration Register Logo"
                                style={{ width: '240px', height: 'auto' }}
                            />
                        </a>
                    </Box>

                    {/* Desktop Navigation Buttons */}
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button
                                key={item.id}
                                color="primary" // Changed from 'inherit' to 'primary' to apply primary theme color
                                onClick={item.subMenu ? (e) => handleMenuOpen(e, item.id) : null}
                                href={!item.subMenu ? item.href : undefined}
                                sx={{ textTransform: 'none', fontWeight: 'bold' }} // Optional: Improve button appearance
                            >
                                {item.title}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Mobile Drawer */}
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }} // Better open performance on mobile
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                {drawer}
            </Drawer>

            {/* Submenu Menus */}
            {navItems.map(
                (item) =>
                    item.subMenu && (
                        <Menu
                            key={item.id}
                            anchorEl={anchorEl}
                            open={openMenuId === item.id}
                            onClose={handleMenuClose}
                        >
                            {item.subMenu.map((subItem) => (
                                <MenuItem
                                    key={subItem.title}
                                    onClick={handleMenuClose}
                                    component="a"
                                    href={subItem.href}
                                >
                                    {subItem.title}
                                </MenuItem>
                            ))}
                        </Menu>
                    )
            )}
        </Box>
    );
};

export default Header;
