// // components/VisaApplicationsData/VisaApplicationsData.js
//
// import React, { useState } from 'react';
// import {
//     Box,
//     Container,
//     Typography,
//     Grid,
//     Card,
//     CardContent,
//     Button,
//     Modal,
//     Paper,
//     Table,
//     TableHead,
//     TableBody,
//     TableRow,
//     TableCell,
//     TableContainer,
//     IconButton,
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
//
// const data = [
//     {
//         id: 1,
//         heading: '1 January 2023 to 30 June 2023',
//         subheading: '4,883 RMAs lodged 313,475 Visa Applications out of a total of 2,570,444.',
//         tableData: [
//             { visaClass: 'Family', rmaUsed: '21,452', totalApplications: '51,962', percentage: '41%' },
//             { visaClass: 'Employer Sponsored', rmaUsed: '13,673', totalApplications: '24,153', percentage: '57%' },
//             { visaClass: 'General Skilled', rmaUsed: '34,882', totalApplications: '63,923', percentage: '55%' },
//             { visaClass: 'Business Skills', rmaUsed: '1,289', totalApplications: '1,891', percentage: '68%' },
//             { visaClass: 'Student', rmaUsed: '61,342', totalApplications: '349,914', percentage: '18%' },
//             { visaClass: 'Visitor', rmaUsed: '46,246', totalApplications: '1,621,934', percentage: '3%' },
//             { visaClass: 'Bridging', rmaUsed: '3,958', totalApplications: '21,777', percentage: '18%' },
//             { visaClass: 'Temporary Protection', rmaUsed: '42', totalApplications: '962', percentage: '4%' },
//             { visaClass: 'Permanent Protection', rmaUsed: '1,829', totalApplications: '24,771', percentage: '7%' },
//             { visaClass: 'Returning Resident', rmaUsed: '6,380', totalApplications: '119,322', percentage: '5%' },
//             { visaClass: 'Temporary Resident', rmaUsed: '94,800', totalApplications: '241,544', percentage: '39%' },
//             { visaClass: 'Temporary Skill Shortage', rmaUsed: '27,582', totalApplications: '48,291', percentage: '57%' },
//             { visaClass: 'TOTAL (in ICSE)', rmaUsed: '313,475', totalApplications: '2,570,444', percentage: '12%' },
//         ],
//         reference: 'https://www.mara.gov.au/notices-and-reports/reports/migration-agent-activity-reports',
//     },
//     {
//         id: 2,
//         heading: '1 January 2022 – 30 June 2022',
//         subheading: '4,868 RMAs lodged 236,435 Visa Applications out of a total of 1,627,169.',
//         tableData: [
//             { visaClass: 'Family', rmaUsed: '15,394', totalApplications: '37,251', percentage: '41%' },
//             { visaClass: 'Employer Sponsored', rmaUsed: '14,809', totalApplications: '24,231', percentage: '61%' },
//             { visaClass: 'General Skilled', rmaUsed: '24,993', totalApplications: '41,250', percentage: '61%' },
//             { visaClass: 'Business Skills', rmaUsed: '5,008', totalApplications: '7,655', percentage: '65%' },
//             { visaClass: 'Student', rmaUsed: '44,572', totalApplications: '253,397', percentage: '18%' },
//             { visaClass: 'Visitor', rmaUsed: '42,712', totalApplications: '957,530', percentage: '5%' },
//             { visaClass: 'Bridging', rmaUsed: '4,135', totalApplications: '23,037', percentage: '18%' },
//             { visaClass: 'Temporary Protection', rmaUsed: '25', totalApplications: '7,864', percentage: '<1%' },
//             { visaClass: 'Permanent Protection', rmaUsed: '557', totalApplications: '4,865', percentage: '11%' },
//             { visaClass: 'Returning Resident', rmaUsed: '5,876', totalApplications: '94,563', percentage: '6%' },
//             { visaClass: 'Temporary Resident', rmaUsed: '57,387', totalApplications: '135,686', percentage: '42%' },
//             { visaClass: 'Temporary Skill Shortage', rmaUsed: '20,967', totalApplications: '39,840', percentage: '53%' },
//             { visaClass: 'TOTAL (in ICSE)', rmaUsed: '236,435', totalApplications: '1,627,169', percentage: '15%' },
//         ],
//         reference: 'https://www.mara.gov.au/notices-and-reports/reports/migration-agent-activity-reports',
//     },
//     {
//         id: 3,
//         heading: '1 July 2022 – 31 December 2022',
//         subheading: '4,839 RMAs lodged 263,702 Visa Applications out of a total of 2,247,584.',
//         tableData: [
//             { visaClass: 'Family', rmaUsed: '16,922', totalApplications: '39,302', percentage: '43%' },
//             { visaClass: 'Employer Sponsored', rmaUsed: '14,983', totalApplications: '25,794', percentage: '58%' },
//             { visaClass: 'General Skilled', rmaUsed: '27,541', totalApplications: '54,691', percentage: '50%' },
//             { visaClass: 'Business Skills', rmaUsed: '4,059', totalApplications: '6,506', percentage: '62%' },
//             { visaClass: 'Student', rmaUsed: '44,518', totalApplications: '252,782', percentage: '18%' },
//             { visaClass: 'Visitor', rmaUsed: '45,608', totalApplications: '1,502,745', percentage: '3%' },
//             { visaClass: 'Bridging', rmaUsed: '3,922', totalApplications: '21,348', percentage: '18%' },
//             { visaClass: 'Temporary Protection', rmaUsed: '29', totalApplications: '2,523', percentage: '1%' },
//             { visaClass: 'Permanent Protection', rmaUsed: '991', totalApplications: '8,400', percentage: '12%' },
//             { visaClass: 'Returning Resident', rmaUsed: '5,939', totalApplications: '109,306', percentage: '5%' },
//             { visaClass: 'Temporary Resident', rmaUsed: '74,442', totalApplications: '178,324', percentage: '42%' },
//             { visaClass: 'Temporary Skill Shortage', rmaUsed: '24,748', totalApplications: '45,863', percentage: '54%' },
//             { visaClass: 'TOTAL (in ICSE)', rmaUsed: '263,702', totalApplications: '2,247,584', percentage: '12%' },
//         ],
//         reference: 'https://www.mara.gov.au/notices-and-reports/reports/migration-agent-activity-reports',
//     },
// ];
//
// const VisaApplicationsData = () => {
//     const [openModal, setOpenModal] = useState(false);
//     const [selectedData, setSelectedData] = useState(null);
//
//     const handleOpenModal = (item) => {
//         setSelectedData(item);
//         setOpenModal(true);
//     };
//
//     const handleCloseModal = () => {
//         setOpenModal(false);
//         setSelectedData(null);
//     };
//
//     return (
//         <Box sx={{ py: 10 }}>
//             <Container>
//                 {/* Section Heading */}
//                 <Box textAlign="center" mb={5}>
//                     <Typography variant="h4" component="h2" gutterBottom>
//                         Data - Visa Applications Lodged by MARA Agents
//                     </Typography>
//                 </Box>
//
//                 {/* Boxes */}
//                 <Grid container spacing={4}>
//                     {data.map((item) => (
//                         <Grid item xs={12} md={4} key={item.id}>
//                             <Card sx={{ height: '100%' }}>
//                                 <CardContent>
//                                     <Typography variant="h6" component="h3" gutterBottom>
//                                         {item.heading}
//                                     </Typography>
//                                     <Typography variant="body1" color="textSecondary" gutterBottom>
//                                         {item.subheading}
//                                     </Typography>
//                                     <Box textAlign="center" mt={2}>
//                                         <Button variant="contained" color="primary" onClick={() => handleOpenModal(item)}>
//                                             View Details
//                                         </Button>
//                                     </Box>
//                                 </CardContent>
//                             </Card>
//                         </Grid>
//                     ))}
//                 </Grid>
//
//                 {/* Modal */}
//                 {selectedData && (
//                     <Modal open={openModal} onClose={handleCloseModal}>
//                         <Box
//                             sx={{
//                                 position: 'absolute',
//                                 top: '50%',
//                                 left: '50%',
//                                 transform: 'translate(-50%, -50%)',
//                                 width: '90%',
//                                 maxWidth: 800,
//                                 bgcolor: 'background.paper',
//                                 borderRadius: 2,
//                                 boxShadow: 24,
//                                 p: 4,
//                             }}
//                         >
//                             <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//                                 <Typography variant="h6">{selectedData.heading}</Typography>
//                                 <IconButton onClick={handleCloseModal}>
//                                     <CloseIcon />
//                                 </IconButton>
//                             </Box>
//                             <Typography variant="body1" mb={2}>
//                                 {selectedData.subheading}
//                             </Typography>
//                             <TableContainer component={Paper}>
//                                 <Table>
//                                     <TableHead>
//                                         <TableRow>
//                                             <TableCell>Visa Class</TableCell>
//                                             <TableCell align="right">RMA Used</TableCell>
//                                             <TableCell align="right">Total Applications</TableCell>
//                                             <TableCell align="right">% Lodged by an RMA</TableCell>
//                                         </TableRow>
//                                     </TableHead>
//                                     <TableBody>
//                                         {selectedData.tableData.map((row, index) => (
//                                             <TableRow key={index}>
//                                                 <TableCell>{row.visaClass}</TableCell>
//                                                 <TableCell align="right">{row.rmaUsed}</TableCell>
//                                                 <TableCell align="right">{row.totalApplications}</TableCell>
//                                                 <TableCell align="right">{row.percentage}</TableCell>
//                                             </TableRow>
//                                         ))}
//                                     </TableBody>
//                                 </Table>
//                             </TableContainer>
//                             <Typography variant="body2" color="textSecondary" mt={2}>
//                                 Reference:{' '}
//                                 <a href={selectedData.reference} target="_blank" rel="noopener noreferrer">
//                                     {selectedData.reference}
//                                 </a>
//                             </Typography>
//                         </Box>
//                     </Modal>
//                 )}
//             </Container>
//         </Box>
//     );
// };
//
// export default VisaApplicationsData;


// components/VisaApplicationsData/VisaApplicationsData.js

import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    Modal,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Cell,
} from 'recharts';

const data = [
    {
        id: 1,
        heading: '1 January 2023 to 30 June 2023',
        subheading: '4,883 RMAs lodged 313,475 Visa Applications out of a total of 2,570,444.',
        tableData: [
            { visaClass: 'Family', rmaUsed: '21,452', totalApplications: '51,962', percentage: '41%' },
            { visaClass: 'Employer Sponsored', rmaUsed: '13,673', totalApplications: '24,153', percentage: '57%' },
            { visaClass: 'General Skilled', rmaUsed: '34,882', totalApplications: '63,923', percentage: '55%' },
            { visaClass: 'Business Skills', rmaUsed: '1,289', totalApplications: '1,891', percentage: '68%' },
            { visaClass: 'Student', rmaUsed: '61,342', totalApplications: '349,914', percentage: '18%' },
            { visaClass: 'Visitor', rmaUsed: '46,246', totalApplications: '1,621,934', percentage: '3%' },
            { visaClass: 'Bridging', rmaUsed: '3,958', totalApplications: '21,777', percentage: '18%' },
            { visaClass: 'Temporary Protection', rmaUsed: '42', totalApplications: '962', percentage: '4%' },
            { visaClass: 'Permanent Protection', rmaUsed: '1,829', totalApplications: '24,771', percentage: '7%' },
            { visaClass: 'Returning Resident', rmaUsed: '6,380', totalApplications: '119,322', percentage: '5%' },
            { visaClass: 'Temporary Resident', rmaUsed: '94,800', totalApplications: '241,544', percentage: '39%' },
            { visaClass: 'Temporary Skill Shortage', rmaUsed: '27,582', totalApplications: '48,291', percentage: '57%' },
            { visaClass: 'TOTAL (in ICSE)', rmaUsed: '313,475', totalApplications: '2,570,444', percentage: '12%' },
        ],
        reference: 'https://www.mara.gov.au/notices-and-reports/reports/migration-agent-activity-reports',
    },
    {
        id: 2,
        heading: '1 January 2022 – 30 June 2022',
        subheading: '4,868 RMAs lodged 236,435 Visa Applications out of a total of 1,627,169.',
        tableData: [
            { visaClass: 'Family', rmaUsed: '15,394', totalApplications: '37,251', percentage: '41%' },
            { visaClass: 'Employer Sponsored', rmaUsed: '14,809', totalApplications: '24,231', percentage: '61%' },
            { visaClass: 'General Skilled', rmaUsed: '24,993', totalApplications: '41,250', percentage: '61%' },
            { visaClass: 'Business Skills', rmaUsed: '5,008', totalApplications: '7,655', percentage: '65%' },
            { visaClass: 'Student', rmaUsed: '44,572', totalApplications: '253,397', percentage: '18%' },
            { visaClass: 'Visitor', rmaUsed: '42,712', totalApplications: '957,530', percentage: '5%' },
            { visaClass: 'Bridging', rmaUsed: '4,135', totalApplications: '23,037', percentage: '18%' },
            { visaClass: 'Temporary Protection', rmaUsed: '25', totalApplications: '7,864', percentage: '<1%' },
            { visaClass: 'Permanent Protection', rmaUsed: '557', totalApplications: '4,865', percentage: '11%' },
            { visaClass: 'Returning Resident', rmaUsed: '5,876', totalApplications: '94,563', percentage: '6%' },
            { visaClass: 'Temporary Resident', rmaUsed: '57,387', totalApplications: '135,686', percentage: '42%' },
            { visaClass: 'Temporary Skill Shortage', rmaUsed: '20,967', totalApplications: '39,840', percentage: '53%' },
            { visaClass: 'TOTAL (in ICSE)', rmaUsed: '236,435', totalApplications: '1,627,169', percentage: '15%' },
        ],
        reference: 'https://www.mara.gov.au/notices-and-reports/reports/migration-agent-activity-reports',
    },
    {
        id: 3,
        heading: '1 July 2022 – 31 December 2022',
        subheading: '4,839 RMAs lodged 263,702 Visa Applications out of a total of 2,247,584.',
        tableData: [
            { visaClass: 'Family', rmaUsed: '16,922', totalApplications: '39,302', percentage: '43%' },
            { visaClass: 'Employer Sponsored', rmaUsed: '14,983', totalApplications: '25,794', percentage: '58%' },
            { visaClass: 'General Skilled', rmaUsed: '27,541', totalApplications: '54,691', percentage: '50%' },
            { visaClass: 'Business Skills', rmaUsed: '4,059', totalApplications: '6,506', percentage: '62%' },
            { visaClass: 'Student', rmaUsed: '44,518', totalApplications: '252,782', percentage: '18%' },
            { visaClass: 'Visitor', rmaUsed: '45,608', totalApplications: '1,502,745', percentage: '3%' },
            { visaClass: 'Bridging', rmaUsed: '3,922', totalApplications: '21,348', percentage: '18%' },
            { visaClass: 'Temporary Protection', rmaUsed: '29', totalApplications: '2,523', percentage: '1%' },
            { visaClass: 'Permanent Protection', rmaUsed: '991', totalApplications: '8,400', percentage: '12%' },
            { visaClass: 'Returning Resident', rmaUsed: '5,939', totalApplications: '109,306', percentage: '5%' },
            { visaClass: 'Temporary Resident', rmaUsed: '74,442', totalApplications: '178,324', percentage: '42%' },
            { visaClass: 'Temporary Skill Shortage', rmaUsed: '24,748', totalApplications: '45,863', percentage: '54%' },
            { visaClass: 'TOTAL (in ICSE)', rmaUsed: '263,702', totalApplications: '2,247,584', percentage: '12%' },
        ],
        reference: 'https://www.mara.gov.au/notices-and-reports/reports/migration-agent-activity-reports',
    },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A569BD', '#5DADE2', '#58D68D', '#F4D03F', '#DC7633', '#AF7AC5', '#5499C7', '#48C9B0', '#F5B041'];

const VisaApplicationsData = () => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const handleOpenModal = (item) => {
        setSelectedData(item);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedData(null);
    };

    // Function to parse percentage strings to numbers
    const parsePercentage = (str) => {
        if (str === '<1%') return 0.5;
        return parseFloat(str.replace('%', ''));
    };

    // Prepare data for the chart
    const getChartData = (tableData) => {
        return tableData
            .filter((item) => item.visaClass !== 'TOTAL (in ICSE)') // Exclude total
            .map((item) => ({
                visaClass: item.visaClass,
                percentage: parsePercentage(item.percentage),
            }));
    };

    return (
        <Box sx={{ py: 10 }}>
            <Container>
                {/* Section Heading */}
                <Box textAlign="center" mb={5}>
                    <Typography variant="h4" component="h2" gutterBottom>
                        Data - Visa Applications Lodged by MARA Agents
                    </Typography>
                </Box>

                {/* Boxes */}
                <Grid container spacing={4}>
                    {data.map((item) => (
                        <Grid item xs={12} md={4} key={item.id}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h6" component="h3" gutterBottom>
                                        {item.heading}
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" gutterBottom>
                                        {item.subheading}
                                    </Typography>
                                    <Box textAlign="center" mt={2}>
                                        <Button variant="contained" color="primary" onClick={() => handleOpenModal(item)}>
                                            View Details
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {/* Modal */}
                {selectedData && (
                    <Modal open={openModal} onClose={handleCloseModal}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '90%',
                                maxWidth: 900,
                                bgcolor: 'background.paper',
                                borderRadius: 2,
                                boxShadow: 24,
                                p: 4,
                                maxHeight: '90vh',
                                overflowY: 'auto',
                            }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                <Typography variant="h6">{selectedData.heading}</Typography>
                                <IconButton onClick={handleCloseModal}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="body1" mb={2}>
                                {selectedData.subheading}
                            </Typography>

                            {/* Chart */}
                            <Box mb={4}>
                                <Typography variant="h6" gutterBottom>
                                    Percentage of Visa Applications Lodged by RMAs
                                </Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart data={getChartData(selectedData.tableData)} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                        <XAxis dataKey="visaClass" angle={-45} textAnchor="end" interval={0} height={80} />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="percentage" fill="#8884d8">
                                            {getChartData(selectedData.tableData).map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>

                            {/* Table */}
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Visa Class</TableCell>
                                            <TableCell align="right">RMA Used</TableCell>
                                            <TableCell align="right">Total Applications</TableCell>
                                            <TableCell align="right">% Lodged by an RMA</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedData.tableData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{row.visaClass}</TableCell>
                                                <TableCell align="right">{row.rmaUsed}</TableCell>
                                                <TableCell align="right">{row.totalApplications}</TableCell>
                                                <TableCell align="right">{row.percentage}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography variant="body2" color="textSecondary" mt={2}>
                                Reference:{' '}
                                <a href={selectedData.reference} target="_blank" rel="noopener noreferrer">
                                    {selectedData.reference}
                                </a>
                            </Typography>
                        </Box>
                    </Modal>
                )}
            </Container>
        </Box>
    );
};

export default VisaApplicationsData;
