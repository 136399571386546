import React, { useState } from 'react';
import {
    Box,
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import TimelineIcon from '@mui/icons-material/Timeline';
import LayersIcon from '@mui/icons-material/Layers';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from '@mui/icons-material/Info';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ErrorIcon from '@mui/icons-material/Error';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HandshakeIcon from '@mui/icons-material/Handshake'


const steps = [
    {
        step: 'Step 1',
        title: 'Registration',
        description:
            'Begin by quickly onboarding clients, users, and partners with minimal information. Progress is tracked through statuses like "Initial Contact," "Awaiting Response," and "Registration Completed."',
        icon: <FolderIcon color="primary" />,
        statuses: [
            { name: "TO DO", explanation: "The registration process has not started yet, and no action has been taken.", icon: <CircleIcon /> },
            { name: "NEED INITIAL DATA", explanation: "The registration process is pending due to missing basic information or documents from the prospect.", icon: <ErrorIcon color="warning" /> },
            { name: "NO DATA AVAILABLE", explanation: "No information has been received from the prospect, and no follow-up has been started.", icon: <HighlightOffIcon color="disabled" /> },
            { name: "INITIAL CONTACT", explanation: "The prospect has been contacted for the first time, and a welcome email has been sent.", icon: <ScheduleIcon color="primary" /> },
            { name: "NOT REACHABLE", explanation: "Multiple attempts have been made to contact the prospect, but they remain unreachable.", icon: <CancelIcon color="error" /> },
            { name: "DONE REGISTRATION", explanation: "All required information has been gathered, and the prospect is fully registered.", icon: <CheckCircleIcon color="success" /> },
            { name: "IN SESSION SETUP", explanation: "The next steps are being prepared, such as setting up a consultation session.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "AWAITING RESPONSE", explanation: "The prospect has been contacted and is awaiting their reply.", icon: <HourglassEmptyIcon color="warning" /> },
            { name: "WITHDRAWN", explanation: "The prospect has voluntarily chosen to withdraw from the registration process.", icon: <CancelIcon color="disabled" /> },
            { name: "EXIT CASE", explanation: "The registration process has been terminated by either the consultancy or the prospect.", icon: <HighlightOffIcon color="error" /> }
        ],
        notifications: [
            "Welcome Email Notifications",
            "Introduction Email Notifications",
            "Status or Stage Change Notifications",
            "Client-Submitted Document Notifications...",
        ],
    },
    {
        step: 'Step 2',
        title: 'Consultation',
        description:
            'Guided consultation sessions with predefined reference questions help agents collect the necessary information. Consultations are tracked with statuses such as "Consultation Scheduled," "In Progress," and "Follow-Up Required."',
        icon: <TimelineIcon color="primary" />,
        statuses: [
            { name: "TO DO", explanation: "No action has been taken yet; the booking process needs to be initiated.", icon: <CircleIcon /> },
            { name: "SCHEDULE THE BOOKING", explanation: "The system or user needs to schedule a consultation.", icon: <ScheduleIcon color="primary" /> },
            { name: "BOOKING SCHEDULED", explanation: "The consultation has been successfully scheduled, with notifications sent.", icon: <CheckCircleIcon color="success" /> },
            { name: "RESCHEDULE REQUESTED", explanation: "A request to reschedule the booking has been made.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "RESCHEDULED", explanation: "The booking has been successfully rescheduled with a new time.", icon: <CheckCircleIcon color="success" /> },
            { name: "BOOKING CANCELED BY PROSPECT", explanation: "The prospect has chosen to cancel the booking.", icon: <CancelIcon color="error" /> },
            { name: "BOOKING CANCELED BY CONSULTANT", explanation: "The migration agent or consultant has canceled the booking.", icon: <CancelIcon color="error" /> },
            { name: "CONSULTATION SCHEDULED", explanation: "The consultation has been scheduled with the prospect and agent.", icon: <ScheduleIcon color="primary" /> },
            { name: "AWAITING CONSULTATION", explanation: "The consultation is scheduled but has not yet started.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "CONSULTATION IN PROGRESS", explanation: "The consultation is currently taking place.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "CONSULTATION COMPLETED", explanation: "The consultation has been completed.", icon: <CheckCircleIcon color="success" /> },
            { name: "PROSPECT NO-SHOW", explanation: "The prospect did not attend the scheduled consultation.", icon: <CancelIcon color="error" /> },
            { name: "FOLLOW-UP REQUIRED", explanation: "A follow-up meeting or action is needed after the consultation.", icon: <HourglassEmptyIcon color="warning" /> },
            { name: "FOLLOW-UP SCHEDULED", explanation: "A follow-up consultation has been scheduled.", icon: <ScheduleIcon color="primary" /> },
            { name: "CONSULTATION CANCELED BY PROSPECT", explanation: "The prospect canceled the consultation before it took place.", icon: <CancelIcon color="error" /> },
            { name: "CONSULTATION RESCHEDULED", explanation: "The consultation was rescheduled to a different date/time and confirmed.", icon: <CheckCircleIcon color="success" /> },
            { name: "CONSULTATION CLOSED - SEND SERVICE AGREEMENT", explanation: "The consultation phase is complete, and the service agreement can be sent.", icon: <DoneIcon color="primary" /> },
            { name: "WITHDRAWN", explanation: "The prospect has voluntarily chosen to withdraw from the consultation process.", icon: <CancelIcon color="disabled" /> },
            { name: "EXIT CASE", explanation: "The consultation process has been terminated by either the consultancy or the prospect.", icon: <HighlightOffIcon color="error" /> }
        ],
        notifications: [
            "Consultation Schedule Notifications",
            "Consultation Outcome Notifications",
            "Status or Stage Change Notifications...",
        ],
    },
    {
        step: 'Step 3',
        title: 'Agreement',
        description:
            'Automatically generate templates for agreements like Form 956 and Client Service Agreements. Track the agreement\'s progress with statuses like "Agreement Sent," "Signed by Client," and "Payment Received."',
        icon: <LayersIcon color="primary" />,
        statuses: [
            { name: "AGREEMENT DRAFTED", explanation: "The agreement (CSA) has been prepared but not yet sent to the prospect.", icon: <CreateIcon color="primary" /> },
            { name: "SEND CSA", explanation: "The Client Service Agreement (CSA) has been sent to the prospect.", icon: <CheckCircleIcon color="success" /> },
            { name: "SENT CSA", explanation: "The CSA has been successfully delivered to the prospect.", icon: <DoneIcon color="primary" /> },
            { name: "RECEIVE SIGNED CSA", explanation: "The prospect has reviewed and signed the CSA.", icon: <CheckCircleIcon color="success" /> },
            { name: "PAYMENT RECEIVED", explanation: "The required payment associated with the agreement has been received.", icon: <CheckCircleIcon color="success" /> },
            { name: "PROSPECT REQUESTED CHANGES", explanation: "The prospect requested changes or modifications to the agreement.", icon: <ErrorIcon color="warning" /> },
            { name: "AGREEMENT RESENT TO PROSPECT", explanation: "The agreement has been resent after modifications.", icon: <DoneIcon color="primary" /> },
            { name: "AGREEMENT SIGNED BY PROSPECT", explanation: "The prospect has signed the agreement and sent it back.", icon: <CheckCircleIcon color="success" /> },
            { name: "FOLLOW-UP REQUIRED", explanation: "The prospect has not responded to the CSA within a timeframe.", icon: <HourglassEmptyIcon color="warning" /> },
            { name: "WITHDRAWN", explanation: "The prospect has voluntarily chosen to withdraw from the agreement process.", icon: <CancelIcon color="disabled" /> },
            { name: "EXIT CASE", explanation: "The agreement process has been terminated by either the consultancy or the prospect.", icon: <HighlightOffIcon color="error" /> }
        ],
        notifications: [
            "Agreement Sent Notifications",
            "Agreement Signed Notifications",
            "Invoice Sent Notifications...",
        ],
    },
    {
        step: 'Step 4',
        title: 'Data Collection',
        description:
            'Clients receive a customized checklist of required documents based on the selected visa type. Documents are tracked from "Documents Requested" to "All Documents Received" with reminders for missing items.',
        icon: <LocalShippingIcon color="primary" />,
        statuses: [
            { name: "DATA COLLECTION INITIATED", explanation: "The data collection process has started.", icon: <FolderIcon color="primary" /> },
            { name: "DOCUMENTS REQUESTED", explanation: "A formal request for required documents has been sent.", icon: <ErrorIcon color="warning" /> },
            { name: "AWAITING DOCUMENTS", explanation: "The team is waiting for the prospect to provide the documents.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "PARTIAL DOCUMENTS RECEIVED", explanation: "Some of the requested documents have been received.", icon: <CheckCircleIcon color="success" /> },
            { name: "ALL DOCUMENTS RECEIVED", explanation: "All requested documents have been successfully received.", icon: <DoneIcon color="primary" /> },
            { name: "DATA VERIFICATION IN PROGRESS", explanation: "The documents are being reviewed and verified for completeness.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "DATA CLARIFICATION REQUIRED", explanation: "Some received documents require clarification.", icon: <ErrorIcon color="warning" /> },
            { name: "ADDITIONAL DOCUMENTS REQUESTED", explanation: "Additional documents have been requested after initial review.", icon: <ErrorIcon color="warning" /> },
            { name: "DOCUMENTS REJECTED", explanation: "Some documents were rejected due to errors or incorrect format.", icon: <CancelIcon color="error" /> },
            { name: "AWAITING MISSING DOCUMENTS", explanation: "Certain documents are still missing.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "DATA COLLECTION COMPLETE", explanation: "All required documents and information have been collected and verified.", icon: <CheckCircleIcon color="success" /> },
            { name: "FOLLOW-UP REQUIRED", explanation: "A follow-up is needed to remind the prospect to provide pending documents.", icon: <HourglassEmptyIcon color="warning" /> },
            { name: "WITHDRAWN", explanation: "The prospect has voluntarily chosen to withdraw from the data collection process.", icon: <CancelIcon color="disabled" /> },
            { name: "EXIT CASE", explanation: "The data collection process has been terminated by either the consultancy or the prospect.", icon: <HighlightOffIcon color="error" /> }
        ],
        notifications: [
            "Client Data Submission Notifications",
            "General Questionnaire Notifications",
            "Status or Stage Change Notifications...",
        ],
    },
    {
        step: 'Step 5',
        title: 'Drafting',
        description:
            'Consultants draft and review visa applications, tracking the drafting process with statuses such as "Draft in Progress," "Sent for Review," and "Amendments Required."',
        icon: <CreateIcon color="primary" />,
        statuses: [
            { name: "VISA APPLICATION DRAFTING IN PROGRESS", explanation: "The application is currently being drafted by the consultant.", icon: <CreateIcon color="primary" /> },
            { name: "SKILL ASSESSMENT APPLICATION DRAFTING IN PROGRESS", explanation: "The skill assessment application is being drafted.", icon: <CreateIcon color="primary" /> },
            { name: "SENT FOR REVIEW TO CASE OFFICER", explanation: "The drafted application has been sent to a reviewer.", icon: <DoneIcon color="primary" /> },
            { name: "AMENDMENTS REQUIRED", explanation: "The draft needs changes or additions before proceeding.", icon: <ErrorIcon color="warning" /> },
            { name: "VISA APPLICATION DRAFT APPROVED", explanation: "The visa application draft has been approved.", icon: <CheckCircleIcon color="success" /> },
            { name: "SKILL ASSESSMENT APPLICATION DRAFT APPROVED", explanation: "The skill assessment draft has been approved.", icon: <CheckCircleIcon color="success" /> },
            { name: "SENT FOR REVIEW TO CLIENT", explanation: "The drafted application has been sent to the client for vetting.", icon: <DoneIcon color="primary" /> },
            { name: "APPLICATION ON HOLD", explanation: "The drafting process is paused, awaiting more information.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "APPLICATION CANCELED", explanation: "The draft has been canceled due to the prospect's decision.", icon: <CancelIcon color="error" /> },
            { name: "WITHDRAWN", explanation: "The prospect has voluntarily chosen to withdraw from the drafting process.", icon: <CancelIcon color="disabled" /> },
            { name: "EXIT CASE", explanation: "The drafting process has been terminated by either the consultancy or the prospect.", icon: <HighlightOffIcon color="error" /> }
        ],
        notifications: [
            "Status or Stage Change Notifications",
            "Application Drafting Update Notifications",
            "Review Submission Notifications...",
        ],
    },
    {
        step: 'Step 6',
        title: 'Finalization',
        description:
            'Finalize and lodge the visa application, with statuses like "Lodged with DOHA," "Request for Further Information," and "Visa Granted."',
        icon: <DoneIcon color="primary" />,
        statuses: [
            { name: "LODGED", explanation: "The application has been successfully lodged with DOHA.", icon: <CheckCircleIcon color="success" /> },
            { name: "WITH DOHA", explanation: "The application is under review by DOHA.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "WITH SKILL ASSESSMENT AUTHORITY", explanation: "The application is under review by the Skill Assessment Authority.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "REQUEST FOR FURTHER INFORMATION (RFI)", explanation: "Additional information is requested by DOHA or the Skill Assessment Authority.", icon: <ErrorIcon color="warning" /> },
            { name: "VISA GRANTED", explanation: "The visa application has been approved and granted.", icon: <CheckCircleIcon color="success" /> },
            { name: "SKILL ASSESSMENT GRANTED", explanation: "The skill assessment application has been approved.", icon: <CheckCircleIcon color="success" /> },
            { name: "SKILL ASSESSMENT REFUSED", explanation: "The skill assessment application has been refused.", icon: <CancelIcon color="error" /> },
            { name: "VISA REFUSED", explanation: "The visa application has been refused.", icon: <CancelIcon color="error" /> },
            { name: "AWAITING DECISION FROM DOHA", explanation: "The application is lodged and awaiting a decision.", icon: <HourglassEmptyIcon color="info" /> },
            { name: "CASE WITHDRAWN", explanation: "The visa application has been withdrawn from the process.", icon: <CancelIcon color="disabled" /> },
            { name: "APPEAL IN PROGRESS", explanation: "An appeal is underway to reconsider the decision.", icon: <ErrorIcon color="warning" /> },
            { name: "CORRESPONDENCE SENT", explanation: "Correspondence related to the application has been sent.", icon: <DoneIcon color="primary" /> }
        ],
        notifications: [
            "Status or Stage Change Notifications",
            "Finalization and Outcome Notifications...",
        ],
    }

];

const roles = [
    {
        title: 'Client',
        description: 'The individual or organization requiring migration services. Clients are the end users who seek assistance in obtaining visas, immigration advice, and other related services.',
        role: 'Clients are the primary beneficiaries of the services offered by the Migration Agency. They provide the necessary information and documents and interact with the system to track their case progress.',
        interactions: 'Clients interact with the system to submit documents, track the status of their visa applications, receive updates, and communicate with the Migration Agency or its partners.',
        icon: <PersonIcon color="primary" />,
    },
    {
        title: 'Migration Agency Staff',
        description: 'The service provider that offers migration-related services, including visa application assistance, consultation, and other immigration services.',
        role: 'The Migration Agency is the central entity in the system, responsible for managing clients, processing visa applications, and providing immigration services.',
        interactions: 'The Migration Agency interacts with clients, partners, and other stakeholders to provide services, manage cases, and ensure successful visa outcomes.',
        icon: <BusinessCenterIcon color="primary" />,
    },
    {
        title: 'Partner Organization',
        description: 'Organizations that work on behalf of clients and liaise with the Migration Agency to ensure the client’s needs are met.',
        role: 'Partners facilitate the migration process by working closely with clients to gather necessary information and documents and coordinate with the Migration Agency.',
        interactions: 'Partners interact with both clients and the Migration Agency, using the system to track the progress of referred clients, submit documents, and communicate with the agency.',
        icon: <HandshakeIcon color="primary" />,
    },
];

const RoleCard = ({ role }) => (
    <Grid item xs={12} md={6} lg={4}>
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                    <ListItemIcon sx={{ minWidth: '40px' }}>{role.icon}</ListItemIcon>
                    <Typography variant="h6" color="primary">{role.title}</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary" mb={2}>{role.description}</Typography>
            </CardContent>
        </Card>
    </Grid>
);

const StepCard = ({ step, handleClickOpen }) => (
    <Grid item xs={12} md={6} lg={4}>
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                    <ListItemIcon sx={{ minWidth: '40px' }}>{step.icon}</ListItemIcon>
                    <Typography variant="h6" color="primary">{step.step}: {step.title}</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary" mb={2}>{step.description}</Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="subtitle1" color="primary" mb={1}>Statuses</Typography>
                <List dense>
                    {step.statuses.map((status, idx) => (
                        <ListItem key={idx} disableGutters>
                            <ListItemIcon>{status.icon}</ListItemIcon>
                            <ListItemText primary={status.name} />
                            <Tooltip title="More info">
                                <IconButton size="small" onClick={() => handleClickOpen(status)}>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ListItem>
                    ))}
                </List>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1" color="primary" mb={1}>Notifications</Typography>
                <List dense>
                    {step.notifications.map((notification, idx) => (
                        <ListItem key={idx} disableGutters>
                            <ListItemIcon><InfoIcon color="action" /></ListItemIcon>
                            <ListItemText primary={notification} />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    </Grid>
);

const HowItWorks = () => {
    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState({});

    const handleClickOpen = (status) => {
        setDialogContent(status);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDialogContent({});
    };

    return (
        <Box component="section" sx={{ pt: 10, pb: 10 }}>
            <Container>
                {/* Header */}
                <Box textAlign="left" mb={5}>
                    <Typography variant="h3" component="h2" color="primary" gutterBottom>
                        What is Migration Register Workflow ?
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <Typography variant="body1" color="textSecondary">
                        The Migration Register system implements a six-step process to manage visa cases effectively.
                        Each stage is structured to ensure efficient case management, with a flexible 'Exit Case' status
                        available if a case is withdrawn. Upon successful completion of all stages, the process concludes
                        with a 'Finish' status, indicating a completed visa application.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" mt={2}>
                        The workflow encompasses three primary components:
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mt={1}>
                        <strong>Roles:</strong> Defined system users with specific permissions and responsibilities.
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mt={1}>
                        <strong>Stages and Statuses:</strong> Distinct stages with corresponding statuses to track case progress accurately.
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mt={1}>
                        <strong>Notifications:</strong> Automated alerts, such as welcome emails, status updates, and tailored notifications
                        based on intelligent triggers, to keep the relevant parties informed at each stage.
                    </Typography>
                </Box>

                {/* Roles Section */}
                <Box textAlign="left" mb={5}>
                    <Typography variant="h5" component="h5" color="primary" gutterBottom>
                        <strong>Roles in the Migration Register Workflow</strong>
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {roles.map((role, index) => (
                            <RoleCard key={index} role={role} />
                        ))}
                    </Grid>
                </Box>

                {/* Steps Section */}
                <Box textAlign="left" mb={5}>
                    <Typography variant="h5" component="h5" color="primary" gutterBottom>
                        <strong>Migration Register Stages, Status and Notifications</strong>
                    </Typography>
                </Box>
                <Grid container spacing={4} justifyContent="center">
                    {steps.map((step, index) => (
                        <StepCard key={index} step={step} handleClickOpen={handleClickOpen} />
                    ))}
                </Grid>

                {/* Dialog for Status Details */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{dialogContent.name}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {dialogContent.explanation}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Container>
        </Box>
    );
};

export default HowItWorks;
