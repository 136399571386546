import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableRow, TableContainer, Paper } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styled from '@emotion/styled';

const faqs = [
    {
        question: "Can this system be developed using Microsoft tools, such as OneDrive and JIRA Work Management?",
        answer: "Yes, our solution integrates tools like Jira Work Management for task tracking, OneDrive for secure document storage, Microsoft Teams for collaboration, and Outlook for automated email notifications. We configure these tools to streamline your operations efficiently.",
    },
    {
        question: "Can you provide a session on how this system is organized?",
        answer: "Absolutely! We offer guided sessions to demonstrate how the tools are configured and used in real-world scenarios, including workflows, task management, and document handling.",
    },
    {
        question: "What benefits can we expect?",
        answer: "You can expect streamlined operations, improved team collaboration, better compliance tracking, and a fully customized workflow tailored to your specific needs.",
    },
    {
        question: "How does onboarding work?",
        answer: "We collaborate with you to understand your workflow and customize the tools accordingly. Onboarding includes setting up configurations, training sessions, and providing step-by-step guidance.",
    },
    {
        question: "How do you collect client data?",
        answer: "We use customized forms and workflows in Jira Service Management to collect client data. The data can be securely stored in OneDrive, and notifications for incomplete or missing information are sent via Outlook.",
    },
    {
        question: "Can you assist with website redevelopment or additional IT services?",
        answer: "Yes, we provide IT services to enhance your digital operations and integrate them seamlessly with the configured tools.",
    },
    {
        question: "Where are you located?",
        answer: "Our office is at 8 Clunies Court, Brisbane Technology Park, Brisbane, Queensland.",
    },
    {
        question: "Why did you start solving migration agents’ problems?",
        answer: "We observed inefficiencies in existing tools and workflows used by migration agents, which inspired us to create tailored solutions for the industry.",
    },
    {
        question: "I need a feature that’s missing. Can you add it?",
        answer: "Yes, we can develop and integrate additional features into the workflow based on your requirements.",
    },
    {
        question: "How does this solution work overall?",
        answer: "The solution leverages advanced configurations of Jira and Microsoft tools to create seamless workflows, automate tasks, and centralize communication.",
    },
    {
        question: "Can we integrate this workflow with JIRA Work Management?",
        answer: "Yes, we specialize in integrating and configuring Jira Work Management to manage tasks, stages, and notifications effectively.",
    },
    {
        question: "Does this solution replace Migration Manager?",
        answer: "Our solution enhances team workflows and collaboration but does not directly replicate the functionality of Migration Manager.",
    },
    {
        question: "Why don’t you offer e-lodge or auto-filling for ImmiAccount?",
        answer: "Our focus is on collaboration and compliance. Automation like e-lodging or form filling may be considered in the future based on demand.",
    },
    {
        question: "How will my clients benefit from this tool?",
        answer: "Clients can securely upload documents, track case progress, and receive automated notifications about updates through the integrated system.",
    },
    {
        question: "What is the pricing structure?",
        answer: "Our pricing includes a one-time setup fee starting at AUD 600 and a monthly support fee of AUD 150 for a team of 12 users. Additional users beyond 5 incur a cost of AUD 7.53 per user. All costs are determined based on the effort and resources required.",
    },
    {
        question: "Is the work management solution free?",
        answer: "Jira Work Management is free for up to 5 users. For additional users, the cost is AUD 7.53 per user.",
    },
    {
        question: "Can the pricing be customized based on our needs?",
        answer: "Yes, the setup and support fees are calculated based on the specific requirements of your business, ensuring reasonable and transparent pricing for the efforts involved.",
    },
    {
        question: "What does the setup fee include?",
        answer: "The setup fee covers system configuration, initial customization, onboarding, and training to align the tools with your workflow.",
    },
    {
        question: "What does the support fee cover?",
        answer: "The monthly support fee includes maintenance, updates, and ongoing assistance to ensure the system runs smoothly and adapts to your evolving needs.",
    },
];



const StyledTableContainer = styled(TableContainer)`
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const FAQTable = () => {
    return (
        <Box sx={{ my: 5, mx: 'auto', maxWidth: '1000px' }}>
            <Typography variant="h4" color="primary" align="center" gutterBottom>
                Frequently Asked Questions
            </Typography>
            <Typography>&nbsp;</Typography>
            <StyledTableContainer component={Paper}>
                <Table aria-label="faq table">
                    <TableBody>
                        {faqs.map((faq, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ width: '35%', borderBottom: 'none' }}>
                                    <Box display="flex" alignItems="center">
                                        <HelpOutlineIcon color="primary" sx={{ mr: 1 }} />
                                        <Typography variant="h6" component="span">
                                            {faq.question}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ width: '65%', borderBottom: 'none' }}>
                                    <Box display="flex" alignItems="center">
                                        <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
                                        <Typography variant="body1" component="span">
                                            {faq.answer}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default FAQTable;
