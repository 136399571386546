import React, { useState } from 'react';
import { Box, Typography, Container, Modal, Paper } from '@mui/material';
import {
    Timeline as MuiTimeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from '@mui/lab';

const events = [
    {
        month: 'April 2024',
        title: 'Conceptual Understanding, Pain Points and Initial Discussions',
        summaryPoints: [
            'Industry Analysis',
            'Identified Pain Points',
            'Lack of IT Ecosystem Familiarity',
            'Inefficient Case Management',
            'Disorganized Information Storage',
            'Accounting Challenges',
            'Dependent Associates',
            'Inconsistent Online Presence',
            'Communication Issues',
            'Workflow Transparency',
            'Outdated Management Solutions',
            'Inefficient Data Collection',
            'Communication Gaps',
            'Billing Inefficiencies',
            'Missing Documentation Templates',
            'Remote Work Challenges',
            'Partner Collaboration Issues',
            'Administrative Overheads',
            'Inefficient Communication Channels',
            'Staff Training Gaps',
        ],
        detailedPoints: [
            'Industry Analysis:',
            'Began exploring the immigration industry to identify how IT solutions could address existing pain points for Migration Agents.',
            'Held initial discussions with Migration Agents to understand their challenges.',
            'Lack of IT Ecosystem Familiarity:',
            'Migration Agents were not hands-on with modern IT tools, leading to reliance on legacy systems.',
            'Inefficient Case Management:',
            'No systematic way to manage visa matters using ticket IDs or workflow stages.',
            'Different visa cases had varying requirements without a structured template.',
            'Disorganized Information Storage:',
            'Documents and communications scattered across emails, various storage systems, and outdated solutions.',
            'Semi-structured document storage led to inefficiencies.',
            'Accounting Challenges:',
            'Invoices and agreements were difficult to track, requiring manual searches through nested folders.',
            'Dependent Associates:',
            'Associates lacked independence, heavily relying on Migration Agents for guidance.',
            'Inconsistent Online Presence:',
            'Websites, social media channels, and Google reviews were managed semi-structurally, often with repetitive content.',
            'Communication Issues:',
            'Remote associates faced difficulties due to inadequate IT systems, relying on WhatsApp and phone calls.',
            'Absence of a booking system; relied on direct calls or walk-ins without proper note-taking.',
            'Workflow Transparency:',
            'Difficulty in tracking the number of active, prospective, or completed visa cases.',
            'Manual checks were required to understand case statuses.',
            'Outdated Management Solutions:',
            'Existing case management tools were expensive, manually installed, and underutilized.',
            'Inefficient Data Collection:',
            'Data collection was time-consuming, requiring manual follow-ups and checks.',
            'Communication Gaps:',
            'Lack of structured updates to clients.',
            'Identified 72+ possible statuses for visa matters but lacked a workflow to manage them.',
            'Billing Inefficiencies:',
            'Creating and sending invoices was cumbersome.',
            'Manual checks needed to identify payments.',
            'Missing Documentation Templates:',
            'Absence of standardized email templates for repetitive scenarios.',
            'Remote Work Challenges:',
            'Difficulty in tracking and collaborating with remote staff.',
            'Lack of effective tools to manage remote workflows.',
            'Partner Collaboration Issues:',
            'Managing different types of partners (providing prospects, converted cases, complex cases, student cases) was challenging.',
            'Required tailored processes for each partner type.',
            'Administrative Overheads:',
            'Managing accounting, reconciliations, refunds, and engagement letters was labor-intensive.',
            'Weekly status updates to active customers were not streamlined.',
            'Inefficient Communication Channels:',
            'Unstructured WhatsApp communications.',
            'Lack of standardized client onboarding processes.',
            'Staff Training Gaps:',
            'Remote team members were not tech-savvy, requiring constant guidance.',
        ],
    },
    {
        month: 'May 2024',
        title: 'Deep Dive into Immigration Processes',
        summaryPoints: [
            'Comprehensive Study',
            'Collaborative Engagement',
            'Pattern Analysis',
            'Industry Research',
        ],
        detailedPoints: [
            'Comprehensive Study:',
            'Studied the entire immigration system end-to-end, including DAMA, assessing authorities, visa types, and legislative frameworks.',
            'Engaged closely with ongoing visa cases to understand real-world applications.',
            'Collaborative Engagement:',
            'Interacted daily with Migration Agent associates to understand case types and workflows.',
            'Observed client communications and interactions throughout the visa process.',
            'Pattern Analysis:',
            'Conducted daily whiteboard sessions to map out visa case patterns, client management strategies, and data interactions.',
            'Industry Research:',
            'Reviewed data from the Department of Home Affairs and other sources.',
            'Made LinkedIn posts to gauge industry interactions and reviewed overall statistics.',
        ],
    },
    {
        month: 'June 2024',
        title: 'Implementing Initial Solutions',
        summaryPoints: [
            'IT Infrastructure Setup',
            'Workflow Design',
            'Partner Collaboration',
            'Documentation and Knowledge Base',
            'Product Management',
            'System Reviews',
            'Exploration of Microsoft Tools',
        ],
        detailedPoints: [
            'IT Infrastructure Setup:',
            'Established Microsoft systems to reduce pain points:',
            'Set up Microsoft emails connected to the Migration Agency\'s domain.',
            'Configured Teams for staff communication, including channels and best practices.',
            'Managed permissions via Azure.',
            'Workflow Design:',
            'Designed and implemented the first workflow using JIRA Work Management.',
            'Created projects for different visa matters.',
            'Developed processes for moving tasks through various statuses.',
            'Trained staff on using JIRA and Teams effectively.',
            'Partner Collaboration:',
            'Designed workflows for partners:',
            'Provided access through JIRA and Teams.',
            'Established processes for case initiation and consultation booking.',
            'Identified initial information requirements for onboarding cases.',
            'Documentation and Knowledge Base:',
            'Created Confluence pages to document frequent information and procedures.',
            'Defined structures for wikis, cases, partner access, and spaces within Confluence.',
            'Product Management:',
            'Product Manager joined over 100 consultations to gain insights.',
            'Continuously refined the solution design through iterative improvements.',
            'System Reviews:',
            'Evaluated existing social media presence, LinkedIn, and websites.',
            'Provided training sessions to partners and identified effective patterns.',
            'Exploration of Microsoft Tools:',
            'Attempted to achieve internal workflows using Microsoft tools:',
            'OneDrive for document storage.',
            'SharePoint as the main dashboard.',
            'DocuSign integration for document signing.',
            'Excel and Word for CSAs and invoices.',
            'Power Automate for emails and notifications.',
            'Microsoft Forms for data collection.',
            'Microsoft Bookings for scheduling.',
        ],
    },
    {
        month: 'July 2024',
        title: 'Identifying the Need for a Custom Solution',
        summaryPoints: [
            'Tool Limitations',
            'Process Refinement',
            'Legacy System Challenges',
            'Decision for Custom Development',
            'MigrationRegister Overview',
        ],
        detailedPoints: [
            'Tool Limitations:',
            'Despite existing tools, discomfort persisted due to lack of clarity and efficiency.',
            'Set up Teams with JIRA notifications, created 72+ status possibilities for visa matters.',
            'Process Refinement:',
            'Finalized document titling conventions and storage methods.',
            'Standardized WhatsApp welcome messaging and client communication protocols.',
            'Legacy System Challenges:',
            'Continued reliance on legacy solutions for questionnaires and document requests proved inadequate.',
            'Decision for Custom Development:',
            'Recognized the necessity for a custom solution tailored to Migration Agents\' needs.',
            'Initiated the development of "MigrationRegister" after evaluating various names.',
            'MigrationRegister Overview:',
            'Purpose:',
            'A cloud-based web application to manage visa-related processes for migration agencies.',
            'Features:',
            'Centralized client registrations and case management.',
            'Streamlined consultations and document collection.',
            'Automated workflows and improved collaboration among staff, clients, and partners.',
            'Compliance with immigration requirements.',
            'Enhanced communication and document tracking.',
            'Invoicing, agreements, and data collection functionalities for operational efficiency.',
        ],
    },
    {
        month: 'August 2024',
        title: 'Design and Development Initiation',
        summaryPoints: [
            'Low-Fidelity Wireframing',
            'Team Collaboration',
        ],
        detailedPoints: [
            'Low-Fidelity Wireframing:',
            'Began designing low-fidelity wireframes to conceptualize the solution.',
            'Engaged in daily and weekly discussions to refine the user interface and user experience.',
            'Team Collaboration:',
            'Worked closely with UI/UX designers to visualize the solution.',
            'Addressed challenges in existing ecosystems that lacked the desired clarity.',
        ],
    },
    {
        month: 'September 2024',
        title: 'Prototyping and Refinement',
        summaryPoints: [
            'Workflow Finalization',
            'UI/UX Development',
            'Prototype Development',
        ],
        detailedPoints: [
            'Workflow Finalization:',
            'Finalized the application\'s workflow based on continuous feedback and iterations.',
            'UI/UX Development:',
            'Tasked UI/UX developers with designing HTML pages for the application.',
            'Prototype Development:',
            'Developed prototypes, engaging in back-and-forth revisions.',
            'Added and removed features based on practicality and user needs.',
        ],
    },
    {
        month: 'October 2024',
        title: 'Progress Towards MVP',
        summaryPoints: ['MVP Development'],
        detailedPoints: [
            'MVP Development:',
            'Made significant progress on the Minimum Viable Product (MVP) for MigrationRegister.',
            'Continued refining the website and application features.',
        ],
    },
];

const Timeline = () => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleOpenModal = (event) => {
        setSelectedEvent(event);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
        setOpenModal(false);
    };

    return (
        <Box sx={{ padding: '50px 0', backgroundColor: '#f5f5f5' }}>
            <Container maxWidth="md">
                <Typography variant="h4" component="h2" align="center" gutterBottom>
                    Project Timeline
                </Typography>
                <MuiTimeline position="alternate">
                    {events.map((event, index) => (
                        <TimelineItem key={index}>
                            <TimelineSeparator>
                                <TimelineDot color="primary" />
                                {index < events.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent sx={{ pb: 4 }}>
                                <Typography variant="h6" component="span">
                                    {event.month}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {event.title}
                                </Typography>
                                {/* Summary Points */}
                                {event.summaryPoints.map((point, idx) => (
                                    <Typography
                                        variant="body2"
                                        key={idx}
                                        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        onClick={() => handleOpenModal(event)}
                                    >
                                        • {point}
                                    </Typography>
                                ))}
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </MuiTimeline>
                {/* Modal for Detailed Points */}
                {selectedEvent && (
                    <Modal open={openModal} onClose={handleCloseModal}>
                        <Paper
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '80%',
                                maxHeight: '80vh',
                                overflowY: 'auto',
                                padding: 4,
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {selectedEvent.month} - {selectedEvent.title}
                            </Typography>
                            {selectedEvent.detailedPoints.map((point, idx) => (
                                <Typography
                                    variant={point.endsWith(':') ? 'subtitle1' : 'body2'}
                                    key={idx}
                                    sx={{ marginLeft: point.endsWith(':') ? 0 : 2, fontWeight: point.endsWith(':') ? 'bold' : 'normal' }}
                                >
                                    {point.endsWith(':') ? point : `• ${point}`}
                                </Typography>
                            ))}
                        </Paper>
                    </Modal>
                )}
            </Container>
        </Box>
    );
};

export default Timeline;

// import React from 'react';
// import { Box, Typography, Container } from '@mui/material';
// import {
//     Timeline as MuiTimeline,
//     TimelineItem,
//     TimelineSeparator,
//     TimelineConnector,
//     TimelineContent,
//     TimelineDot,
// } from '@mui/lab';
//
// const events = [
//     {
//         month: 'April 2024',
//         title: 'Conceptual Understanding and Initial Discussions',
//         points: [
//             'Industry Analysis:',
//             'Began exploring the immigration industry to identify how IT solutions could address existing pain points for Migration Agents.',
//             'Held initial discussions with Migration Agents to understand their challenges.',
//             'Identified Pain Points:',
//             'Lack of IT Ecosystem Familiarity:',
//             'Migration Agents were not hands-on with modern IT tools, leading to reliance on legacy systems.',
//             'Inefficient Case Management:',
//             'No systematic way to manage visa matters using ticket IDs or workflow stages.',
//             'Different visa cases had varying requirements without a structured template.',
//             'Disorganized Information Storage:',
//             'Documents and communications scattered across emails, various storage systems, and outdated solutions.',
//             'Semi-structured document storage led to inefficiencies.',
//             'Accounting Challenges:',
//             'Invoices and agreements were difficult to track, requiring manual searches through nested folders.',
//             'Dependent Associates:',
//             'Associates lacked independence, heavily relying on Migration Agents for guidance.',
//             'Inconsistent Online Presence:',
//             'Websites, social media channels, and Google reviews were managed semi-structurally, often with repetitive content.',
//             'Communication Issues:',
//             'Remote associates faced difficulties due to inadequate IT systems, relying on WhatsApp and phone calls.',
//             'Absence of a booking system; relied on direct calls or walk-ins without proper note-taking.',
//             'Workflow Transparency:',
//             'Difficulty in tracking the number of active, prospective, or completed visa cases.',
//             'Manual checks were required to understand case statuses.',
//             'Outdated Management Solutions:',
//             'Existing case management tools were expensive, manually installed, and underutilized.',
//             'Inefficient Data Collection:',
//             'Data collection was time-consuming, requiring manual follow-ups and checks.',
//             'Communication Gaps:',
//             'Lack of structured updates to clients.',
//             'Identified 72+ possible statuses for visa matters but lacked a workflow to manage them.',
//             'Billing Inefficiencies:',
//             'Creating and sending invoices was cumbersome.',
//             'Manual checks needed to identify payments.',
//             'Missing Documentation Templates:',
//             'Absence of standardized email templates for repetitive scenarios.',
//             'Remote Work Challenges:',
//             'Difficulty in tracking and collaborating with remote staff.',
//             'Lack of effective tools to manage remote workflows.',
//             'Partner Collaboration Issues:',
//             'Managing different types of partners (providing prospects, converted cases, complex cases, student cases) was challenging.',
//             'Required tailored processes for each partner type.',
//             'Administrative Overheads:',
//             'Managing accounting, reconciliations, refunds, and engagement letters was labor-intensive.',
//             'Weekly status updates to active customers were not streamlined.',
//             'Inefficient Communication Channels:',
//             'Unstructured WhatsApp communications.',
//             'Lack of standardized client onboarding processes.',
//             'Staff Training Gaps:',
//             'Remote team members were not tech-savvy, requiring constant guidance.',
//         ],
//     },
//     {
//         month: 'May 2024',
//         title: 'Deep Dive into Immigration Processes',
//         points: [
//             'Comprehensive Study:',
//             'Studied the entire immigration system end-to-end, including DAMA, assessing authorities, visa types, and legislative frameworks.',
//             'Engaged closely with ongoing visa cases to understand real-world applications.',
//             'Collaborative Engagement:',
//             'Interacted daily with Migration Agent associates to understand case types and workflows.',
//             'Observed client communications and interactions throughout the visa process.',
//             'Pattern Analysis:',
//             'Conducted daily whiteboard sessions to map out visa case patterns, client management strategies, and data interactions.',
//             'Industry Research:',
//             'Reviewed data from the Department of Home Affairs and other sources.',
//             'Made LinkedIn posts to gauge industry interactions and reviewed overall statistics.',
//         ],
//     },
//     {
//         month: 'June 2024',
//         title: 'Implementing Initial Solutions',
//         points: [
//             'IT Infrastructure Setup:',
//             'Established Microsoft systems to reduce pain points:',
//             'Set up Microsoft emails connected to the Migration Agency\'s domain.',
//             'Configured Teams for staff communication, including channels and best practices.',
//             'Managed permissions via Azure.',
//             'Workflow Design:',
//             'Designed and implemented the first workflow using JIRA Work Management.',
//             'Created projects for different visa matters.',
//             'Developed processes for moving tasks through various statuses.',
//             'Trained staff on using JIRA and Teams effectively.',
//             'Partner Collaboration:',
//             'Designed workflows for partners:',
//             'Provided access through JIRA and Teams.',
//             'Established processes for case initiation and consultation booking.',
//             'Identified initial information requirements for onboarding cases.',
//             'Documentation and Knowledge Base:',
//             'Created Confluence pages to document frequent information and procedures.',
//             'Defined structures for wikis, cases, partner access, and spaces within Confluence.',
//             'Product Management:',
//             'Product Manager joined over 100 consultations to gain insights.',
//             'Continuously refined the solution design through iterative improvements.',
//             'System Reviews:',
//             'Evaluated existing social media presence, LinkedIn, and websites.',
//             'Provided training sessions to partners and identified effective patterns.',
//             'Exploration of Microsoft Tools:',
//             'Attempted to achieve internal workflows using Microsoft tools:',
//             'OneDrive for document storage.',
//             'SharePoint as the main dashboard.',
//             'DocuSign integration for document signing.',
//             'Excel and Word for CSAs and invoices.',
//             'Power Automate for emails and notifications.',
//             'Microsoft Forms for data collection.',
//             'Microsoft Bookings for scheduling.',
//         ],
//     },
//     {
//         month: 'July 2024',
//         title: 'Identifying the Need for a Custom Solution',
//         points: [
//             'Tool Limitations:',
//             'Despite existing tools, discomfort persisted due to lack of clarity and efficiency.',
//             'Set up Teams with JIRA notifications, created 72+ status possibilities for visa matters.',
//             'Process Refinement:',
//             'Finalized document titling conventions and storage methods.',
//             'Standardized WhatsApp welcome messaging and client communication protocols.',
//             'Legacy System Challenges:',
//             'Continued reliance on legacy solutions for questionnaires and document requests proved inadequate.',
//             'Decision for Custom Development:',
//             'Recognized the necessity for a custom solution tailored to Migration Agents\' needs.',
//             'Initiated the development of "MigrationRegister" after evaluating various names.',
//             'MigrationRegister Overview:',
//             'Purpose:',
//             'A cloud-based web application to manage visa-related processes for migration agencies.',
//             'Features:',
//             'Centralized client registrations and case management.',
//             'Streamlined consultations and document collection.',
//             'Automated workflows and improved collaboration among staff, clients, and partners.',
//             'Compliance with immigration requirements.',
//             'Enhanced communication and document tracking.',
//             'Invoicing, agreements, and data collection functionalities for operational efficiency.',
//         ],
//     },
//     {
//         month: 'August 2024',
//         title: 'Design and Development Initiation',
//         points: [
//             'Low-Fidelity Wireframing:',
//             'Began designing low-fidelity wireframes to conceptualize the solution.',
//             'Engaged in daily and weekly discussions to refine the user interface and user experience.',
//             'Team Collaboration:',
//             'Worked closely with UI/UX designers to visualize the solution.',
//             'Addressed challenges in existing ecosystems that lacked the desired clarity.',
//         ],
//     },
//     {
//         month: 'September 2024',
//         title: 'Prototyping and Refinement',
//         points: [
//             'Workflow Finalization:',
//             'Finalized the application\'s workflow based on continuous feedback and iterations.',
//             'UI/UX Development:',
//             'Tasked UI/UX developers with designing HTML pages for the application.',
//             'Prototype Development:',
//             'Developed prototypes, engaging in back-and-forth revisions.',
//             'Added and removed features based on practicality and user needs.',
//         ],
//     },
//     {
//         month: 'October 2024',
//         title: 'Progress Towards MVP',
//         points: [
//             'MVP Development:',
//             'Made significant progress on the Minimum Viable Product (MVP) for MigrationRegister.',
//             'Continued refining the website and application features.',
//         ],
//     },
// ];
//
// const Timeline = () => {
//     return (
//         <Box sx={{ padding: '50px 0', backgroundColor: '#f5f5f5' }}>
//             <Container maxWidth="md">
//                 <Typography variant="h4" component="h2" align="center" gutterBottom>
//                     Project Timeline
//                 </Typography>
//                 <MuiTimeline position="alternate">
//                     {events.map((event, index) => (
//                         <TimelineItem key={index}>
//                             <TimelineSeparator>
//                                 <TimelineDot color="primary" />
//                                 {index < events.length - 1 && <TimelineConnector />}
//                             </TimelineSeparator>
//                             <TimelineContent sx={{ pb: 4 }}>
//                                 <Typography variant="h6" component="span">
//                                     {event.month}
//                                 </Typography>
//                                 <Typography variant="subtitle1" gutterBottom>
//                                     {event.title}
//                                 </Typography>
//                                 {event.points.map((point, idx) => (
//                                     <Typography
//                                         variant={point.endsWith(':') ? 'body1' : 'body2'}
//                                         key={idx}
//                                         sx={{ marginLeft: point.endsWith(':') ? 0 : 2 }}
//                                     >
//                                         {point.endsWith(':') ? <strong>{point}</strong> : `• ${point}`}
//                                     </Typography>
//                                 ))}
//                             </TimelineContent>
//                         </TimelineItem>
//                     ))}
//                 </MuiTimeline>
//             </Container>
//         </Box>
//     );
// };
//
// export default Timeline;
