// src/components/Footer/Footer.js

import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Link,
    IconButton,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Container
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PropTypes from 'prop-types';

const Footer = () => {
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const [termsOfServiceOpen, setTermsOfServiceOpen] = useState(false);
    const [showCookieMessage, setShowCookieMessage] = useState(() => {
        return localStorage.getItem("cookieConsentGiven") !== "true";
    });

    const handlePrivacyPolicyOpen = () => {
        setPrivacyPolicyOpen(true);
    };

    const handlePrivacyPolicyClose = () => {
        setPrivacyPolicyOpen(false);
    };

    const handleTermsOfServiceOpen = () => {
        setTermsOfServiceOpen(true);
    };

    const handleTermsOfServiceClose = () => {
        setTermsOfServiceOpen(false);
    };

    const handleCookieConsent = () => {
        localStorage.setItem("cookieConsentGiven", "true");
        setShowCookieMessage(false);
    };

    return (
        <>
            {/* Cookie Consent Message */}
            {showCookieMessage && (
                <Box sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#fff',
                    boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
                    padding: '16px',
                    textAlign: 'center',
                    zIndex: 1000,
                }}>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        We use cookies to ensure you get the best experience on our website. By continuing to use our site, you agree to our use of cookies.
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleCookieConsent}>
                        Got it!
                    </Button>
                </Box>
            )}

            {/* Footer Content */}
            <Box sx={{ backgroundColor: '#161E45', color: '#fff', padding: '40px 0' }}>
                <Container maxWidth="lg">
                    <Grid container spacing={4}>
                        {/* Contact Us Section */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Contact Us
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                GetPost Labs Pty Ltd<br />
                                8 Clunies Ross Ct,<br />
                                Brisbane Technology Park,<br />
                                Queensland 4113
                            </Typography>
                            <Button
                                variant="outlined"
                                color="inherit"
                                href="https://getpostlabs.io/about/contact-us"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ mt: 1 }}
                            >
                                Contact Us
                            </Button>
                            <Box sx={{ mt: 2 }}>
                                <IconButton color="inherit" href="https://www.linkedin.com/company/getpostlabs/" target="_blank" rel="noopener noreferrer">
                                    <LinkedInIcon />
                                </IconButton>
                            </Box>
                        </Grid>

                        {/* Legal Section */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>
                                Legal
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handlePrivacyPolicyOpen}
                                    underline="hover"
                                    color="inherit"
                                    sx={{ textAlign: 'left', cursor: 'pointer' }}
                                >
                                    Privacy Policy
                                </Link>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleTermsOfServiceOpen}
                                    underline="hover"
                                    color="inherit"
                                    sx={{ textAlign: 'left', cursor: 'pointer' }}
                                >
                                    Terms of Service
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

                {/* Footer Bottom */}
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Typography variant="body2">
                        &copy; {new Date().getFullYear()} Migration Register. All rights reserved.
                    </Typography>
                </Box>

                {/* Privacy Policy Dialog */}
                <Dialog
                    open={privacyPolicyOpen}
                    onClose={handlePrivacyPolicyClose}
                    aria-labelledby="privacy-policy-dialog-title"
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle id="privacy-policy-dialog-title">Privacy Policy</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="body1" paragraph>
                                Your privacy is important to us. It is our policy to respect your privacy regarding any information we may collect from you across our website, and other sites we own and operate. We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification. We don't share any personally identifying information publicly or with third parties, except when required to by law.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services. Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information.
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handlePrivacyPolicyClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Terms of Service Dialog */}
                <Dialog
                    open={termsOfServiceOpen}
                    onClose={handleTermsOfServiceClose}
                    aria-labelledby="terms-of-service-dialog-title"
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle id="terms-of-service-dialog-title">Terms of Service</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="body1" paragraph>
                                By using our website and services, you agree to comply with these terms and conditions. Please read them carefully.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                We grant you a limited, non-exclusive, non-transferable, and revocable license to use our services, subject to these terms. We reserve the right to terminate your license to use our services if you violate these terms.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Our services and all content, including but not limited to text, images, graphics, logos, and software, are the property of our company and its licensors and are protected by copyright and other intellectual property laws.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of our services or content without express written permission from us. You also agree not to modify, adapt, reverse-engineer, or otherwise alter our services or content.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                We do not guarantee that our services will be error-free or uninterrupted, and we are not responsible for any loss, damage, or inconvenience you may experience as a result of using our services. We disclaim all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no representations or warranties regarding the accuracy, reliability, or completeness of our services or content.
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleTermsOfServiceClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
};

Footer.propTypes = {
    // Define prop types if needed
};

export default Footer;
