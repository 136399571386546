// import React from 'react';
// import { Box, Container, Typography, Grid } from '@mui/material';
// import TeamMemberCard from './TeamMemberCard'; // Import the TeamMemberCard component
// import { styled } from '@mui/system';
//
// // Add the teamMembers array here
// const teamMembers = [
//     {
//         name: 'Saumya Sachan',
//         role: 'Product Manager',
//         description: `Saumya has a solid background in product management, having strengthened her skills across various sectors including FMCG, IT, and E-commerce. Her recent role involved spearheading product strategies and enhancing operational efficiencies, which resulted in significant business impacts. With a Master’s degree in Product Management from Carnegie Mellon University, Saumya is well-equipped to drive innovation and excellence.`,
//         linkedin: 'https://www.linkedin.com/in/saumya-sachan93/',
//         image: 'saumya.jpg', // Placeholder for the image filename
//     },
//     {
//         name: 'Sumit Arora',
//         role: 'Solution Design and Development Lead',
//         description: `Sumit develops full-stack engineering business applications and solutions, starting from scratch and leading projects through to completion. He has led teams and developed solutions from startup phases to major banks, logistics, supply chain, enterprise mobile security, and forest data solutions. Sumit provides a complete overview from start to finish, including initial discoveries, discussions, tools used, technologies, their advantages and disadvantages, gap analysis, and how the solution evolved step by step.`,
//         linkedin: 'https://www.linkedin.com/in/sumitarora/',
//         image: 'sumit.jpg', // Placeholder for the image filename
//     },
//     // {
//     //     name: 'Rohit Sharma',
//     //     role: 'Principal Migration Agent',
//     //     description: `Rohit is the Principal Migration Agent at Migration Star, dedicated to helping individuals navigate the complexities of migration. With over a decade of experience, his passion lies in understanding each client's unique needs and finding the best solutions to ensure successful migration outcomes. He has a strong track record, including representing clients successfully before the Administrative Tribunal. Under his leadership, Migration Star has become a leading resource for those seeking expert guidance in education and migration matters.`,
//     //     linkedin: 'https://www.linkedin.com/in/rohit-sharma-010b44210/',
//     //     image: 'rohit.jpg', // Placeholder for the image filename
//     // },
//     // Add more team members as needed
// ];
//
// const TeamSection = styled(Box)(({ theme }) => ({
//     paddingTop: theme.spacing(15),
//     paddingBottom: theme.spacing(15),
// }));
//
// const Team = () => {
//     return (
//         <TeamSection>
//             <Container>
//                 {/* Section Heading */}
//                 <Box textAlign="center" mb={5}>
//                     <Typography variant="h5" component="h4" color="primary" gutterBottom>
//                         Our Team
//                     </Typography>
//                     <Typography variant="h3" component="h2" gutterBottom>
//                         The People Behind MigrationRegister
//                     </Typography>
//                     {/*<Typography variant="body1" color="textSecondary">*/}
//                     {/*    Intrinsically strategize cutting-edge functionalities before interoperable applications. Synergistically incubate extensive expertise through integrated intellectual capital.*/}
//                     {/*</Typography>*/}
//                 </Box>
//                 {/* Team Members */}
//                 <Grid container spacing={4}>
//                     {teamMembers.map((member, index) => (
//                         <Grid item xs={12} sm={6} md={4} key={index}>
//                             <TeamMemberCard member={member} />
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Container>
//         </TeamSection>
//     );
// };
//
// export default Team;

import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import TeamMemberCard from './TeamMemberCard'; // Import the TeamMemberCard component
import { styled } from '@mui/system';

// Add the teamMembers array here
const teamMembers = [
    {
        name: 'Saumya Sachan',
        role: 'Product Manager',
        description: `Saumya has a solid background in product management, having strengthened her skills across various sectors including FMCG, IT, and E-commerce. Her recent role involved spearheading product strategies and enhancing operational efficiencies, which resulted in significant business impacts. With a Master’s degree in Product Management from Carnegie Mellon University, Saumya is well-equipped to drive innovation and excellence.`,
        linkedin: 'https://www.linkedin.com/in/saumya-sachan93/',
        image: 'saumya.jpg', // Placeholder for the image filename
    },
    {
        name: 'Sumit Arora',
        role: 'Solution Design and Development Lead',
        description: `Sumit develops full-stack engineering business applications and solutions, starting from scratch and leading projects through to completion. He has led teams and developed solutions from startup phases to major banks, logistics, supply chain, enterprise mobile security, and forest data solutions. Sumit provides a complete overview from start to finish, including initial discoveries, discussions, tools used, technologies, their advantages and disadvantages, gap analysis, and how the solution evolved step by step.`,
        linkedin: 'https://www.linkedin.com/in/sumitarora/',
        image: 'sumit.jpg', // Placeholder for the image filename
    },
];

const TeamSection = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
}));

const Team = () => {
    return (
        <TeamSection>
            <Container>
                {/* Section Heading */}
                <Box textAlign="center" mb={5}>
                    <Typography variant="h5" component="h4" color="primary" gutterBottom>
                        Our Team
                    </Typography>
                    <Typography variant="h3" component="h2" gutterBottom>
                        The People Behind MigrationRegister
                    </Typography>
                </Box>
                {/* Team Members */}
                <Grid container spacing={4} justifyContent="center">
                    {teamMembers.map((member, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <TeamMemberCard member={member} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </TeamSection>
    );
};

export default Team;
